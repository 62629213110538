import { RestaurantServiceType } from 'libs/common/src/models/enum/RestaurantServiceType';
import { Packages } from 'libs/common/src/models/enum/Subscription';
import { OrsysContextProviderValues, useOrsysContext } from 'libs/common/src/utils/OrsysContext';
import { RestaurantMinimalReadDTO, UserDetailedReadDTO } from '@orsys/common';

export const visibleBySubscription: (
  orsysContext: OrsysContextProviderValues,
  packages?: Packages[],
  restaurantServiceTypes?: RestaurantServiceType[]
) => boolean = (orsysContext, packages, restaurantServiceTypes) => {
  const { user, restaurant, restaurantServiceType } = orsysContext;

  return checkVisibility({
    user,
    restaurant,
    restaurantServiceTypes,
    restaurantServiceType,
    packages
  });
};

export const useVisibleBySubscription = (
  packages?: Packages[],
  restaurantServiceTypes?: RestaurantServiceType[]
) => {
  const { user, restaurant, restaurantServiceType } = useOrsysContext();

  return checkVisibility({
    user,
    restaurant,
    restaurantServiceTypes,
    restaurantServiceType,
    packages
  });
};

const checkVisibility = ({ user, packages, restaurant, restaurantServiceTypes, restaurantServiceType }: {
  user?: UserDetailedReadDTO | null,
  restaurant?: RestaurantMinimalReadDTO | null,
  packages?: Packages[],
  restaurantServiceTypes?: RestaurantServiceType[],
  restaurantServiceType?: RestaurantServiceType | null,
}) => {
  if (user == null) {
    return false;
  }

  let isAvailable = true;
  let tempIsAvailable = false;

  if (packages && packages.length > 0) {
    if (restaurant && restaurant.activeSubscriptions) {
      restaurant.activeSubscriptions.map((packageTextId) => {
        if (!tempIsAvailable) {
          tempIsAvailable = !!packages.find((pkg) => pkg === (packageTextId as unknown));
        }
      });

      if (!tempIsAvailable) {
        isAvailable = false;
      }
    } else {
      isAvailable = false;
    }
  }

  if (restaurantServiceTypes && restaurantServiceTypes.length > 0) {
    if (restaurantServiceType) {
      tempIsAvailable = restaurantServiceTypes.includes(restaurantServiceType);
    }

    if (!tempIsAvailable) {
      isAvailable = false;
    }
  }

  return isAvailable;
};
