import React, { FC, useContext, useEffect, useState } from 'react';
import { Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SubMenu } from 'react-pro-sidebar';
import { injectIntl, useIntl } from 'react-intl';
import { WithIntlComponentProps } from 'libs/common/src/utils/i18n';
import { useRouter, withRouter } from 'next/router';
import { WithRouterProps } from 'next/dist/client/with-router';
import { useVisibleByRoles, visibleByRoles } from 'libs/common/src/utils/RoleUtils';
import { OrsysContext, useOrsysContext } from 'libs/common/src/utils/OrsysContext';
import { useVisibleBySubscription, visibleBySubscription } from '../../SubscriptionControl/SubscriptionControl';
import styles from './Sidebar.module.scss';
import Link from 'next/link';
import { parse } from 'next-useragent';
import { RouteObject, routes } from './SidebarRoutes';

interface SidebarProps extends WithRouterProps, WithIntlComponentProps {}

const Sidebar: FC<SidebarProps> = (props) => {
	const context = useOrsysContext();

	const [activeRoutes, setActiveRoutes] = useState<RouteObject[]>([]);

	const processRoutes = () => {
		setActiveRoutes(
			routes.filter(
				(route) =>
					visibleByRoles(context, route.systemRoles, route.restaurantRoles) &&
					visibleBySubscription(context, route.packages, route.restaurantServiceTypes),
			),
		);
	};

	useEffect(() => {
		processRoutes();
	}, []);

	useEffect(() => {
		processRoutes();
	}, [context]);

	return (
		<ProSidebar collapsed={!context.menuIsOpen}>
			<SidebarContent>
				<Menu>{activeRoutes.map((route) => renderMenuItem(route))}</Menu>
			</SidebarContent>
			<SidebarFooter></SidebarFooter>
		</ProSidebar>
	);
};

export default withRouter<SidebarProps>(injectIntl(Sidebar));

const renderMenuItem = (route: RouteObject, parentPath?: string, parentPathTemplate?: string) => {
  return <MenuItemComponent route={route} parentPath={parentPath} parentPathTemplate={parentPathTemplate} />;
};


const MenuItemComponent: FC<{
  route: RouteObject, parentPath?: string, parentPathTemplate?: string
}> = ({ route, parentPath, parentPathTemplate }) => {
  const router = useRouter();
  const intl = useIntl();

  const { menuIsOpen, restaurant, setMenuIsOpen } = useOrsysContext();
  const visibleBySubscription = useVisibleBySubscription(route.packages, route.restaurantServiceTypes);
  const visibleByRoles = useVisibleByRoles(route.systemRoles, route.restaurantRoles);

  const actualPathTemplate = `${parentPathTemplate ? parentPathTemplate : ''}/${route.pathTemplate}`;
  const actualPath = `${parentPath ? parentPath : ''}/${route.path}`;
  const matchPath = route.matchPath ? router.asPath.includes(route.matchPath) : true;
  const isActive = route.matchPaths ? (route.matchPaths || []).includes(router.asPath) :
    (route.exactMatch
      ? router.pathname === actualPathTemplate
      : router.pathname.startsWith(actualPathTemplate)) && matchPath;

  if (route.hideForDemoAccounts && restaurant?.deleteAfterSubscriptionsExpire === true) {
    return <></>;
  }

  if (route.children) {
    return (
      <SubMenu
        title={intl.formatMessage({ id: route.textKey })}
        defaultOpen={isActive}
        icon={route.icon}>
        {route.children
          .filter((route) => {
            return (
              visibleByRoles && visibleBySubscription
            );
          })
          .map((child) => renderMenuItem(child, actualPath, actualPathTemplate))}
      </SubMenu>
    );
  } else if (route.externalPath) {
    return (
      <a
        href={route.externalPath}
        className={styles.menuLink}
        target="_blank"
        onClick={() => {
          try {
            const userAgent = parse(window.navigator.userAgent);
            if (!userAgent.isDesktop) {
              setMenuIsOpen(false);
            }
          } catch (e) {
          }
        }}>
        <MenuItem
          icon={route.icon}
          active={isActive}>
          <span>{intl.formatMessage({ id: route.textKey })}</span>
        </MenuItem>
      </a>
    );
  } else {
    return (
      <Link
        href={actualPathTemplate}
        as={actualPath}
        passHref
        shallow
        className={styles.menuLink}
        onClick={() => {
          try {
            const userAgent = parse(window.navigator.userAgent);
            if (!userAgent.isDesktop) {
              setMenuIsOpen(false);
            }
          } catch (e) {
          }
        }}>
        <MenuItem
          icon={route.icon}
          active={isActive}>
          <span>{intl.formatMessage({ id: route.textKey })}</span>
        </MenuItem>
      </Link>
    );
  }
};
